import styled from "styled-components";

const ClientAppContainer = styled.div`
  height: calc(100vh - 60px);
  display: grid;
  grid-template-columns: 25% 75%;
  grid-column-gap: 0;
`;
const ClientAppSideNav = styled.div`
  border-right: 1px solid #eee;
`;
const ClientAppMainContainer = styled.div`
  padding: 30px;
  overflow-y: auto;
`;

export { ClientAppContainer, ClientAppSideNav, ClientAppMainContainer };
