import React, { lazy } from "react";
import { retry } from "utils/retry";
import { Routes } from "./routes-constants";
const Home = lazy(() => retry(() => import("pages/Auth/Home/index")));
export interface RoutesInterface {
  path: string;
  exact?: boolean;
  isPrivate: boolean;
  isAdmin?: boolean;
  component: React.LazyExoticComponent<() => React.ReactElement> | any;
}

const AppRoutes: RoutesInterface[] = [
  {
    path: "/",
    exact: true,
    isPrivate: false,
    component: Home
  },
  {
    path: Routes.Home,
    exact: true,
    isPrivate: false,
    component: Home
  },
  {
    path: "*",
    exact: true,
    isPrivate: false,
    component: Home
  }
];

export { AppRoutes };
