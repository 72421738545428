import styled from "styled-components";

const AuthFooterContainer = styled.div`
  border-top: 1px solid #edeff5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  margin: 0 50px;

  p {
    margin: 0;
    padding: 0;
    color: #7b7b7b;
    font-size: 14px;
  }
`;

const AuthSocialContainer = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 50px;

  svg {
    margin: 0 7px;
    cursor: pointer;
  }
`;

const LinksContainer = styled.div`
  display: flex;

  p {
    cursor: pointer;
    font-size: 14px;
    color: #232323;
    padding: 0 10px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialIconsContainer = styled.div`
  display: flex;
`;

export {
  AuthFooterContainer,
  AuthSocialContainer,
  LinksContainer,
  SocialIconsContainer
};
