import React from "react";
import {
  ClientAppContainer,
  ClientAppMainContainer,
  ClientAppSideNav
} from "./styles";

const ClientLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <ClientAppContainer>
      <ClientAppSideNav>
      </ClientAppSideNav>
      <ClientAppMainContainer>{props.children}</ClientAppMainContainer>
    </ClientAppContainer>
  );
};

export default ClientLayout;
