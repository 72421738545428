import React from "react";
import {
  AuthFooterContainer,
  AuthSocialContainer,
  LinksContainer,
  SocialIconsContainer
} from "./styles";
import { ReactComponent as Instagram } from "assets/svg/instagram.svg";
import { ReactComponent as Twitter } from "assets/svg/twitter.svg";
import { ReactComponent as Facebook } from "assets/svg/facebook.svg";

const AuthFooter = () => {
  return (
    <>
      <AuthSocialContainer>
        <SocialIconsContainer>
          <Instagram />
          <Twitter />
          <Facebook />
        </SocialIconsContainer>

        <LinksContainer>
          <p>Terms</p>
          <p>Privacy</p>
        </LinksContainer>
      </AuthSocialContainer>
      <AuthFooterContainer>
        <p>© 2019 AellaCredit, All Rights Reserved</p>
        <p>Services are offered by Flourish Microfinance Bank.</p>
      </AuthFooterContainer>
    </>
  );
};

export default AuthFooter;
