export default {
  pri: "#2054d2",
  sec: "#123077",
  ter: "#737373",
  white: "#fff",
  error: "#f84f31",
  alt: "#E5B7A0",
  "brd-clr": "#c5d4f6",
  progress: {
    500: "#27AE60"
  },
  "invest-progress": {
    500: "#2054D2"
  }
};
