import styled from "styled-components";
import { device } from "styles";

const AuthContainer = styled.div`
  height: calc(100vh - 65px - 65px);
`;

const IllustrationWrapper = styled.div`
  display: none;

  @media ${device.tablet} {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: auto;
      height: auto;
      display: block;
      max-width: 100%;
      object-fit: cover;
      max-width: 500px;
    }
  }
`;

const MainContent = styled.div`
  display: flex;
  margin-top: 3rem;

  @media ${device.tablet} {
    height: calc(100% - 3rem);
    margin-top: 8rem;
    padding: 4rem 0 0;
    margin-top: 0;

    align-items: center;
    justify-content: center;
  }
`;

export { AuthContainer, IllustrationWrapper, MainContent };
