import React from "react";
import ClientRouting from "routes/ClientRouting";
import { loginKey } from "utils/constants";
import storage from "utils/storage";
import Auth from "./auth";

const Client = () => {
  const token = storage.get(loginKey);
  return <div>{!token ? <Auth /> : <ClientRouting />}</div>;
};

export default Client;
