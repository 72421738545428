import styled from "styled-components";

const AuthNavContainer = styled.div`
  height: 65px;
  padding: 0 50px;
  display: flex;
  align-items: center;
`;

export { AuthNavContainer };
