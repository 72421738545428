import React from "react";
import { AuthNavContainer } from "./styles";
import { ReactComponent as Logo } from "assets/svg/logo.svg";

const AuthNav = () => {
  return (
    <AuthNavContainer>
      <Logo width={60} height={60} />
    </AuthNavContainer>
  );
};

export default AuthNav;
