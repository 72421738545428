import AuthFooter from "components/AuthFooter";
import AuthNav from "components/AuthNav";
import { AuthContainer } from "pages/Auth/styles";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "routes/routes-constants";
import { AppRoutes } from "routes/routes.config";

const Auth = () => {
  const AuthRoutes = AppRoutes.filter((route) => !route.isPrivate);
  return (
    <>
      <AuthNav />
      <AuthContainer>
        <Switch>
          {AuthRoutes.map((path) => (
            <Route
              key={path.path}
              exact={path.exact}
              path={path.path}
              component={path.component}
            />
          ))}

          <Redirect to={Routes.Home} />
        </Switch>
      </AuthContainer>
      <AuthFooter />
    </>
  );
};

export default Auth;
