import { createGlobalStyle } from "styled-components";
import colors from "./colors";

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1025px",
  laptopL: "1441px",
  desktop: "2561px"
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

const GlobalStyles = createGlobalStyle`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }


    *::-webkit-scrollbar {
        display: none;
    }

    * {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        font-size: 15px;
        min-height: 100%;
        font-family: "upgrade", sans-serif;
        -webkit-font-smoothing: antialiased;
        outline: none;
        background: #ffffff;
      }

      p,
      a {
        font-size: 0.875rem;
        font-family: "upgrade", sans-serif;
      }

      /* core styles for table */

.table{
  width: 100%;
  font-size: 0.78rem;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: var(--tableBgColor, #fff);
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid ${colors.pri};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.table__thead{
  background-color: var(--tableTitleBgColor);
  color: var(--tableTitleTextColor);
}

.table__mobile-caption{
  display: none;
}

.table__td, .table__th{
  padding: var(--tableCellPadding, 20px);
  box-sizing: border-box;
}


.table__tr {
  border-bottom: 1px solid #e2e2e2;
}

.table__tr:last-child {
  border-bottom: none;
}

@media screen and (min-width: 769px){

  .table__tr:hover{
    background-color: var(--tableRowBgColorHover);
  }

  .table__td:first-child, .table__th:first-child{
    text-align: left;
  }

  .table__td, .table__th{
    text-align: center;
  }
}

@media screen and (max-width: 768px){

  .table, .table__thead, .table__tbody, .table__tr{
    display: block;
  }

  .table__td{
    display: flex;
  }

  .table__head{
    display: none;
  }

  .table__mobile-title{
    background-color: var(--tableTitleBgColor);
    color: var(--tableTitleTextColor);
  }

  .table__td{
    border-bottom: 1px solid var(--tableSeparatorColor);
  }

  .table__td:last-of-type{
    border-bottom: none;
  }

  .table__mobile-caption, .table__value{
    display: block;
    width: 50%;
    box-sizing: border-box;
  }

  .table__mobile-caption{
    padding-right: 2%;
  }
}

.table{
  --tableCellPadding: 1.5rem;
  --tableTitleBgColor: #fff;
  --tableTitleTextColor: #a4a7b0;
  --tableRowBgColorHover: #f0f0f0;
  --tableSeparatorColor: #eee;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0
}

.dropdown-content-items {
  cursor: pointer
}

.dropdown:hover .dropdown-content {
  display: block;
}

.custom-dropdown {
  .custom-select-wrapper {
      position: relative;
      user-select: none;
      width: 100%;
  }
  .custom-select__div {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0 !important;
  }
  .custom-select__trigger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 22px;
      font-weight: 300;
      color: #3b3b3b;
      height: 48px;
      font-size: 13px;
      line-height: 60px;
      background: #ffffff;
      cursor: pointer;
      border: 1px solid #212121;
      border-radius: 5px;
  }
  .custom-dropdown__option {
      position: absolute;
      border-radius: 6px;
      display: block;
      top: 58px;
      left: 0;
      right: 0;
      border: 0.5px solid #eaeaea;
      background: #000921;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      z-index: 2147483647;
      max-height: 250px;
      overflow-y: auto;
      box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
      -webkit-box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
      -moz-box-shadow: 10px 17px 48px -16px rgba(0,0,0,0.2);
  }
  .custom-select__div .custom-dropdown__option {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
  }
  .custom-option {
      position: relative;
      display: block;
      padding: 0 22px 0 22px;
      font-weight: 300;
      color: #fff;
      font-size: 13.5px;
      line-height: 40px;
      cursor: pointer;
      transition: all 0.5s;
  }
  .custom-option:hover {
      cursor: pointer;
      background-color: #b2b2b2;
  }
  .custom-option.selected {
    color: #fff;
    background-color: #1c1c1e;
  }

  .arrow {
      position: relative;
      height: 8px;
      width: 8px;
  }
  .arrow::before,
  .arrow::after {
      content: "";
      position: absolute;
      bottom: 0px;
      width: 0.15rem;
      height: 100%;
      transition: all 0.5s;
  }
  .arrow::before {
      left: -5px;
      transform: rotate(45deg);
      background-color: $primary-color;
  }
  .arrow::after {
      left: 0;
      transform: rotate(-45deg);
      background-color: $primary-color;
  }
  .open .arrow::before {
      left: -5px;
      transform: rotate(-45deg);
  }
  .open .arrow::after {
      left: 0;
      transform: rotate(45deg);
  }
}
`;

export default GlobalStyles;
