import ClientLayout from "layout/Client/ClientLayout";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Routes } from "./routes-constants";
import { AppRoutes } from "./routes.config";

const ClientRouting = () => {
  const ClientRoutes = AppRoutes.filter(
    (route) => route.isPrivate && !route.isAdmin
  );
  return (
    <>
      <ClientLayout>
        <Switch>
          {ClientRoutes.map((path) => (
            <Route
              key={path.path}
              exact={path.exact}
              path={path.path}
              component={path.component}
            />
          ))}

          <Redirect to={Routes.Home} />
        </Switch>
      </ClientLayout>
    </>
  );
};

export default ClientRouting;
